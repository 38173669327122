import format from 'date-fns/format'
import isBefore from 'date-fns/isBefore'
import React, { useState } from 'react'
import RCDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateRangePickerHeader } from './components/DateRangePickerHeader'
import DateRangePickerInput from './DateRangePickerInput'
import './styles.scss'

export interface DateRangePickerProps {
  selected?: Date
  maxDate?: Date | null
  minDate?: Date | null
  defaultDates?: Date[]
  placeholder?: string[]
  format?: string
  isMobile?: boolean
  className?: string
  label?: string
  onChange?: (dates: any) => void
}

const renderDayContents = (day, date) => {
  return <span className="mr-4">{day}</span>
}

const DateRangePicker = (props: DateRangePickerProps) => {
  const {
    onChange,
    label,
    placeholder = ['', ''],
    defaultDates = [null, null],
    format: dateFormat = 'EEE, dd MMM',
    className = '',
    isMobile,
  } = props
  const [dateRange, setDateRange] = useState([...defaultDates])
  const [startDate, endDate] = dateRange
  const [selectStart, setSelectStart] = useState(true)

  const calendarRef = React.useRef<any>(null)

  const handleChange = (dates: any) => {
    const [startDate, endDate] = dateRange

    if ((selectStart ? isBefore(dates[0], endDate) : isBefore(startDate, dates[0])) || !endDate) {
      let newDates = dates
      if (startDate && endDate) newDates = selectStart ? [dates[0], endDate] : [startDate, dates[0]]

      setDateRange(newDates)
      setSelectStart(!selectStart)

      if (onChange) onChange(newDates)
    }
  }

  const closeCalendar = () => {
    calendarRef.current.cancelFocusInput()
    calendarRef.current.setOpen(false)
  }

  const activeClass = (date: Date) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const [start, end] = [startDate, endDate]
    if (start) start.setHours(0, 0, 0, 0)
    if (end) end.setHours(0, 0, 0, 0)

    const todayClass = date.getTime() === today.getTime() ? 'golf-date-picker__today' : undefined

    const startActiveClass = start && date.getTime() === start.getTime() ? 'golf-date-picker__start' : undefined

    const endActiveClass = end && date.getTime() === end.getTime() ? 'golf-date-picker__end' : undefined

    const rangeColor =
      start && end && date.getTime() >= start.getTime() && date.getTime() <= end.getTime()
        ? 'golf-date-picker__range'
        : undefined

    return [rangeColor, startActiveClass, endActiveClass, todayClass].join(' ')
  }

  const openStartDate = startDate ?? new Date()
  const openEndDate = endDate ?? new Date()
  const openToDate = selectStart ? openStartDate : openEndDate

  const startDateValue = startDate ? format(startDate, dateFormat) : ''
  const endDateValue = endDate ? format(endDate, dateFormat) : ''

  return (
    <div className={`date-range-picker-container ${className}`}>
      {label && <span className="mr-40 label">{label}</span>}
      <RCDatePicker
        selectsStart={selectStart}
        selectsEnd={!selectStart}
        onChange={handleChange}
        startDate={startDate}
        ref={calendarRef}
        endDate={endDate}
        shouldCloseOnSelect={!selectStart && startDate != null}
        openToDate={openToDate}
        inline={isMobile}
        onClickOutside={closeCalendar}
        renderDayContents={renderDayContents}
        monthsShown={2}
        renderCustomHeader={(headerProps) => <DateRangePickerHeader {...headerProps} onClose={closeCalendar} />}
        dayClassName={activeClass}
        customInput={
          <DateRangePickerInput
            startDatePlaceholder={placeholder[0]}
            endDatePlaceholder={placeholder[1]}
            startDate={startDateValue}
            endDate={endDateValue}
            onClickInput={() => setSelectStart(true)}
          />
        }
        selectsRange
      />
      {isMobile && (
        <div className="golf-modal__datepicker--inputs">
          <div className="flex-col d-flex">
            <span>From</span>
            <input type="text" value={startDateValue} onClick={() => setSelectStart(true)} readOnly />
          </div>
          <div className="flex-col d-flex">
            <span>To</span>
            <input type="text" value={endDateValue} onClick={() => setSelectStart(false)} readOnly />
          </div>
        </div>
      )}
    </div>
  )
}

export default DateRangePicker
