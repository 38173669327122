import request from './request'

export default class NotificationsService {
  static getAll() {
    return request({
      url: `/notifications`,
      method: 'GET',
    })
  }
  static markAsRead(notificationId) {
    return request({
      url: `/notifications/${notificationId}/mark_as_read`,
      method: 'PUT',
      data: {},
    })
  }
  static markAllAsRead() {
    return request({
      url: `/notifications/mark_all_as_read`,
      method: 'PUT',
      data: {},
    })
  }
}
