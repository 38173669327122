import format from "date-fns/format";
import React, { useEffect } from "react";
import { EventFilters } from "src/Types/Event";
import Button from "../Buttons";
import DateRangePicker from "../Datepickers/DateRangePicker";
import "./styles.scss";

interface DatePickerModalProps {
  isOpen: boolean;
  close: () => void;
  dateRange: Date[];
  filter: (filters: Partial<EventFilters>) => void;
}

const DatePickerModal = (props: DatePickerModalProps) => {
  const { dateRange } = props;
  const handleDatesChange = ([startDate, endDate]) => props.filter({ date_from: format(startDate, "yyyy/MM/dd"), date_to: endDate ? format(endDate, "yyyy/MM/dd") : null });

  useEffect(() => {
    document.body.style.overflow = props.isOpen ? "hidden" : "unset";
  }, [props.isOpen]);

  const dateFormat = "EEE, dd MMM";
  return (
    <div className="golf-modal__datepicker is-active">
      <Button type={"close-modal reset"} hasIcon={true} iconName={"golf-icon close ultra-dark-grey-icon is-large"} onClick={props.close} />
      <div>
        <DateRangePicker onChange={handleDatesChange} defaultDates={dateRange} format={dateFormat} isMobile />
        <hr className="border-t-2 border-ultra-light-grey" />
        <Button type={"mt-20 primary-full-w"} label={"Confirm Dates"} onClick={props.close} />
      </div>
    </div>
  );
};

export default DatePickerModal;
