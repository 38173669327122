import React from 'react'
import ReactDOM from 'react-dom'
import 'src/utils/polyfills'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'
import { stopReportingRuntimeErrors } from 'react-error-overlay'

// if (process.env.NODE_ENV === 'development') stopReportingRuntimeErrors()

export const honeybadger = Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: 'production',
})

ReactDOM.render(
  <React.StrictMode>
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <App />
    </HoneybadgerErrorBoundary>
  </React.StrictMode>,

  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
