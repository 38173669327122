import React, { useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import Icon from 'src/Components/Icons'
import './styles.scss'

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type: string
  label?: string
  hasIcon?: boolean
  disabled?: boolean
  iconName?: string
  hoverIconName?: string
  iconClassName?: string
  isSubmit?: boolean
  loading?: boolean
  loadingIconSize?: number
  setDisableAfterClick?: boolean
}

function Button(props: ButtonProps) {
  const {
    type,
    isSubmit,
    disabled,
    loading,
    label,
    iconName,
    hoverIconName,
    iconClassName,
    loadingIconSize,
    hasIcon,
    onClick,
    setDisableAfterClick,
    ...rest
  } = props

  const [clickDisabled, setClickDisabled] = useState(false)

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!clickDisabled) {
      if (setDisableAfterClick) setClickDisabled(true)
      if (onClick) onClick(ev)
    }
  }

  return (
    <button
      disabled={disabled || loading || clickDisabled}
      onClick={handleClick}
      className={`${loading ? 'is-loading relative' : ''} ${type}-btn ${props.hasIcon ? 'has-icon' : ''}`}
      type={isSubmit ? 'submit' : 'button'}
      {...rest}>
      {label && <span style={loading ? { opacity: 0 } : {}}>{label}</span>}
      {hasIcon && !loading && !iconClassName && <span className={`golf-icon ${iconName}`}></span>}
      {hasIcon && !loading && iconClassName && <Icon name={iconName} className={`golf-icon ${iconClassName}`} />}
      {hasIcon && !loading && iconClassName && hoverIconName && (
        <Icon name={hoverIconName} className={`golf-icon hover-icon ${iconClassName}`} />
      )}
      {loading && (
        <div
          className="d-flex items-center justify-center absolute"
          style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <ClipLoader color={'#fff'} loading={loading} size={loadingIconSize ?? 24} />
        </div>
      )}
    </button>
  )
}

Button.defaultProps = {
  type: 'primary',
} as Partial<ButtonProps>

export default Button
