import React from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";

interface MetaProps {
  title: string;
  description?: string;
  image?: string;
  preppended?: boolean;
}

const Meta = ({ title = "Golf Genius Software - Golf Hub", preppended, image, description }: MetaProps) => {
  let location = useLocation();
  title = preppended ? "Golf Genius Software - Golf Hub - " + title : title;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={location.pathname} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image:url" content={image} />
      <meta property="og:image:secure_url" content={image} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={location.pathname} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
    </Helmet>
  );
};

export default Meta;
