import 'react-placeholder/lib/reactPlaceholder.css'
import 'url-search-params-polyfill'
import './styles.scss'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { history, persistor, store } from '../../store'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

interface Props {
  children: any
}

const TypedConnectedRouter = ConnectedRouter as any

const Layout = (props: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <TypedConnectedRouter history={history}>{props.children}</TypedConnectedRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  )
}

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store
}

export default Layout
