import React, { InputHTMLAttributes, useEffect, useState } from 'react'
import { Range, getTrackBackground } from 'react-range'
import Input from '.'
import './styles.scss'

interface SliderInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onSlide: (value: number) => void
  radiusValue: number
  suffix?: string
}

const SliderInput = (props: SliderInputProps) => {
  const [values, setValues] = useState([props.radiusValue])

  useEffect(() => {
    setValues([props.radiusValue])
  }, [props.radiusValue])

  const handleChange = (values: number[]) => {
    setValues(values)
  }

  return (
    <div className={`golf-slider-input ${props.className ?? ''}`}>
      <Range
        values={values}
        step={1}
        min={1}
        max={150}
        onFinalChange={(values) => props.onSlide(values[0])}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '2px',
              width: '100%',
              backgroundColor: '#ccc',
            }}>
            <div
              ref={props.ref}
              style={{
                height: '2px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: values,
                  colors: ['#FF6633', '#ccc'],
                  min: 1,
                  max: 150,
                }),
                alignSelf: 'center',
              }}>
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '24px',
              width: '24px',
              borderRadius: '40px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
            }}></div>
        )}
      />
      <input
        onChange={(e) => {
          const value = parseInt(e.target.value)
          if (value > 0 && value < 100) {
            handleChange([value])
            props.onSlide(value)
          }
        }}
        type="number"
        name="radius"
        value={values[0]}
        className={`golf-input tw-min-w-[64px] tw-ml-2`}
      />
      <span>{props.suffix}</span>
    </div>
  )
}

export default SliderInput
