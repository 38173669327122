import { User } from "src/Types/User";

const initialState: any = {
  user: {},
};

export const SignIn = (user: User) => ({
  type: "SIGN_IN",
  payload: { ...user },
});

export const SignUp = (user: User) => ({
  type: "SIGN_UP",
  payload: { ...user },
});

export const UpdateProfile = (user: User) => ({
  type: "UPDATE_PROFILE",
  payload: { ...user },
});

export const updateProfileImage = (image: string) => ({
  type: "UPDATE_PROFILE_IMAGE",
  payload: image,
});

export const SignOut = () => ({
  type: "SIGN_OUT",
  payload: {},
});

const userReducer = (state = initialState, action) => {
  const payload = { ...action.payload, time: new Date().getTime() };

  switch (action.type) {
    case "SIGN_IN":
      return {
        ...state,
        user: {
          ...payload,
        },
      };
    case "SIGN_UP":
      return {
        ...state,
        user: {
          ...payload,
        },
      };
    case "UPDATE_PROFILE":
      return {
        ...state,
        user: {
          ...payload,
        },
      };
    case "UPDATE_PROFILE_IMAGE":
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user?.profile,
            logo: action.payload,
          },
        },
      };
    case "SIGN_OUT":
      return {
        ...state,
        user: {},
      };
    default:
      return state;
  }
};

export const getUser = (state) => state.user;

export default userReducer;
