import { ScorecardInfoType } from "src/Types/Event";

export const scorecardInfoPlaceholder: ScorecardInfoType = {
  player: [{ gross_score: { scores: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18], totals: { out: 47, in: 48, total: 49 } }, net_score: null, name: "Placeholder Player" }],
  tee: {
    id: 1,
    name: "Placeholder Scorecard",
    rating: null,
    slope: null,
    hole_data: {
      handicap: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
      par: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
      par_sums: [45, 145],
      yardage: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
      yardage_sums: [45, 145],
    },
  },
};
