import { AxiosError } from 'axios'
import omit from 'lodash/omit'
import reverse from 'lodash/reverse'
import values from 'lodash/values'
import { UseQueryOptions } from '@tanstack/react-query'
import NotificationsService from 'src/api/Notifications'
import { Notification } from 'src/Types/Notification'

import useGolfQuery from './useGolfQuery'

const getAllNotifications = async (): Promise<Notification[]> => {
  const data = await NotificationsService.getAll()
  return data
}

export default function useNotifications(options?: UseQueryOptions<Notification[], AxiosError, Notification[]>) {
  const {
    data: notifications,
    isLoading,
    error,
    refetch,
  } = useGolfQuery(['notifications'], () => getAllNotifications(), options)
  const notificationsCount = notifications
    ? notifications.filter((notification) => notification.read_at == null && notification.message).length
    : 0

  return {
    notifications: reverse(values(omit(notifications, 'meta'))) as Notification[],
    notificationsCount,
    isLoading,
    error,
    refetch,
  }
}
