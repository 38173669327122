import './styles.scss'
import { useHistory } from 'react-router-dom'
import NotificationsService from 'src/api/Notifications'
import useNotifications from 'src/hooks/useNotifications'

import Button from '../Buttons'
import NotificationItem from './NotificationItem'

interface NotificationDropdownProps {
  closeDropdown?: () => void
  closeModal?: () => void
  notificationsAllRead: boolean
}

const NotificationDropdown = (props: NotificationDropdownProps) => {
  const { closeDropdown, closeModal } = props

  const { notifications, refetch } = useNotifications({
    staleTime: 1000 * 5,
    cacheTime: 1000 * 5,
    retry: 0,
  })

  const isEmpty = notifications.length === 0

  const history = useHistory()

  const handleMarkAsRead = (notificationId: string, eventId?: string) => {
    NotificationsService.markAsRead(notificationId).then(() => {
      refetch()
      if (eventId) {
        if (props.closeDropdown) closeDropdown()
        if (props.closeModal) closeModal()
        history.push(`/events/${eventId}`)
      }
    })
  }

  return (
    <div className={`golf-dropdown__notifications pt-20 sm_desktop:pt-16 is-active`}>
      <div className="d-flex items-start px-20 justify-between is-1-of-1 mb-16">
        <p className="font-extrabold leading-20 golf-modal__title pt-42 sm_desktop:pt-0">Notifications</p>
        {window.innerWidth < 1280 && (
          <Button
            type={'close-modal reset'}
            hasIcon={true}
            iconName={'golf-icon close ultra-dark-grey-icon is-large'}
            onClick={props.closeModal}
          />
        )}
      </div>
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          text={notification.message}
          className={!notification.read_at && !props.notificationsAllRead ? 'is-unread' : ''}
          onClickNotification={() => handleMarkAsRead(notification.id, notification.redirect_to_id)}
        />
      ))}
      {isEmpty && (
        <div className="golf-empty--notification mt-32 is-1-of-1">
          <figure></figure>
          <p className="mt-12 font-extrabold text-center">You're all caught up!</p>
          <p className="text-center mt-12 mb-16">You have no notifications.</p>
        </div>
      )}
    </div>
  )
}

export default NotificationDropdown
