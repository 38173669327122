import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { persistCombineReducers, persistStore } from "redux-persist";
import expireReducer from "redux-persist-expire";
import storage from "redux-persist/es/storage";
import thunk from "redux-thunk";
import { requestInterceptor, responseInterceptor } from "../api/request";
import rootReducer from "./reducers";
import eventFilters from "./reducers/eventFilters";
import eventHistory from "./reducers/eventHistory";
import locationReducer from "./reducers/location";
import modals from "./reducers/modals";
import registrationReducer, { clearRegistration, updateError } from "./reducers/registration";
import registrationRoundsReducer from "./reducers/registrationRounds";
import userReducer from "./reducers/user";
import registrationInfoReducer from "./reducers/registrationInfo";

const loggerMiddleWare = (store) => (next) => (action) => {
  if (process.env.NODE_ENV !== "production") console.log("[LOG] Action triggered", action);
  next(action);
};

const locationMiddleWare = (store) => (next) => (action) => {
  if (typeof action === "function") {
    return action(store.dispatch, store.getState);
  }
  if (action.type === "@@router/LOCATION_CHANGE") {
    store.dispatch(updateError(null));
  }
  next(action);
};

const userMiddleware = (store) => (next) => (action) => {
  if (action.type === "SIGN_OUT") {
    store.dispatch(clearRegistration());
  }
  next(action);
};

export const history = createBrowserHistory({ basename: process.env.REACT_APP_BASENAME });
history.listen((location, action) => {
  if (!["page", "registration", "?section"].some((path) => location.search.includes(path))) {
    window.scrollTo(0, 0);
  }
});

const config = {
  key: "golfdirectory",
  storage,
  blacklist: ["router", "search", "eventFilters", "modals", "registrationRoundsReducer"],
  transforms: [
    expireReducer("locationReducer", {
      expireSeconds: 60 * 20,
      autoExpire: true,
    }),
  ],
};
const reducer = persistCombineReducers(config, {
  userReducer,
  eventFilters,
  registrationReducer,
  locationReducer,
  modals,
  eventHistory,
  registrationRoundsReducer,
  registrationInfoReducer,
  ...rootReducer(history),
});

function configureStore() {
  let store = createStore(reducer, applyMiddleware(thunk, routerMiddleware(history), loggerMiddleWare, locationMiddleWare, userMiddleware));
  // @ts-ignore
  requestInterceptor(store);
  responseInterceptor(store);
  let persistor = persistStore(store);
  return { persistor, store };
}

export const { persistor, store } = configureStore();
