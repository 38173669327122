import { ScorecardInfoType, ScoreType } from "src/Types/Event";
import range from "lodash/range";
import get from "lodash/get";
import sum from "lodash/sum";
import { uid } from "react-uid";
import "./styles.scss";

interface PastScoreCardProps {
  className?: string;
  scorecardInfo?: ScorecardInfoType;
  isFront?: boolean;
}

function PastScoreCard(props: PastScoreCardProps) {
  const yards = get(props, "scorecardInfo.tee.hole_data.yardage", []);
  const pars = get(props, "scorecardInfo.tee.hole_data.par", []);
  const handicaps = get(props, "scorecardInfo.tee.hole_data.handicap", []);
  const slope = get(props, "scorecardInfo.tee.slope", "");
  const rating = get(props, "scorecardInfo.tee.rating", "");
  const score: ScoreType = get(props, "scorecardInfo.player[0].gross_score", null);
  const adjScore: ScoreType = get(props, "scorecardInfo.player[0].net_score", null);

  const startHole = props.isFront ? 0 : 9;
  const endHole = props.isFront ? 9 : 18;
  return (
    <table className={`golf-past-event__scorecard ${props.className ?? ""}`}>
      <thead>
        <tr>
          <th>Hole</th>
          {range(1, 10).map((th) => (
            <th key={uid(th)}>{th}</th>
          ))}
          <th>OUT</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Yards{" "}
            {(slope || rating) && (
              <span>
                {slope}/{rating}
              </span>
            )}
          </td>
          {yards.slice(startHole, endHole).map((yard, index) => (
            <td key={uid(index)}>{yard}</td>
          ))}
          <td>{sum(yards.slice(startHole, endHole))}</td>
        </tr>
        <tr>
          <td>Par</td>
          {pars.slice(startHole, endHole).map((par, index) => (
            <td key={uid(index)}>{par}</td>
          ))}
          <td>{sum(pars.slice(startHole, endHole))}</td>
        </tr>
        <tr>
          <td>Stroke Index</td>
          {handicaps.slice(startHole, endHole).map((item, index) => (
            <td key={uid(index)}>{item}</td>
          ))}
          <td>{sum(handicaps.slice(startHole, endHole))}</td>
        </tr>
        {score && (
          <tr>
            <td>Gross Score</td>
            {score.scores.slice(startHole, endHole).map((item, index) => (
              <td key={uid(index)}>{item}</td>
            ))}
            <td>{sum(score.scores.slice(startHole, endHole))}</td>
          </tr>
        )}
        {/* {adjScore && (<tr>
          <td>Adj Score</td>
          {adjScore.scores.slice(startHole, endHole).map((item) => <td>{item}</td>)}
          <td>{sum(adjScore.scores.slice(startHole, endHole))}</td>
        </tr>)} */}
      </tbody>
    </table>
  );
}

export default PastScoreCard;
