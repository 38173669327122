import isEqual from 'date-fns/isEqual'
import React, { useMemo } from 'react'
import Icon from 'src/Components/Icons'

interface DateRangePickerInputProps {
  startDatePlaceholder?: string
  endDatePlaceholder?: string
  startDateLabel?: string
  endDateLabel?: string
  endDate?: string
  startDate?: string
  dayOfWeek?: string
  isSameDate?: boolean
  onClick?: () => void
  onClickInput?: () => void
}

const DateRangePickerInput = React.forwardRef((props: DateRangePickerInputProps, ref: any) => {
  const { onClick, onClickInput, startDate, endDate, dayOfWeek, isSameDate } = props

  const handleClickInput = () => {
    onClickInput()
    if (onClick) onClick()
  }

  const displayedDate = useMemo(() => {
    let displayedDate = ''

    if (dayOfWeek) {
      displayedDate = 'Every ' + dayOfWeek
      if (startDate) displayedDate += ', from ' + startDate
      if (endDate && !isSameDate) displayedDate += ' to ' + endDate
    } else if (startDate) {
      displayedDate = startDate
      if (endDate && !isSameDate) displayedDate += ' - ' + endDate
    }

    if (displayedDate === '') displayedDate = 'Date'

    return displayedDate
  }, [dayOfWeek, endDate, isSameDate, startDate])

  return (
    <div
      className="tw-cursor-pointer tw-border tw-gap-1 tw-h-[38px] tw-flex tw-items-center tw-border-gray-200 tw-rounded tw-pr-2"
      ref={ref}
      onClick={handleClickInput}>
      <span className={`tw-pl-12 ${displayedDate === 'Date' ? 'text-md-grey' : ''}`}>{displayedDate}</span>
      <Icon
        name="calendar"
        className="golf-icon cursor-pointer no-margin !tw-ml-auto !tw-right-3 tw-absolute orange-icon !tw-right-auto !tw-ml-3"
        onClick={onClick}
      />
    </div>
  )
})

DateRangePickerInput.displayName = 'DateRangePickerInput'

export default DateRangePickerInput
