import React from 'react'
import format from 'date-fns/format'
import './styles.scss'
import Icon from 'src/Components/Icons'

interface DateRangePickerHeaderProps {
  date: Date
  title?: string
  onClose?: () => void
  changeYear(year: number): void
  changeMonth(month: number): void
  decreaseMonth(): void
  increaseMonth(): void
  decreaseYear(): void
  increaseYear(): void
  prevMonthButtonDisabled: boolean
  nextMonthButtonDisabled: boolean
  prevYearButtonDisabled: boolean
  nextYearButtonDisabled: boolean
  minYear?: number
  maxYear?: number
}

export const DateRangePickerHeader = (props: DateRangePickerHeaderProps) => {
  const { date, decreaseMonth, increaseMonth, title = 'Pick dates' } = props

  return (
    <div className="react-datepicker__custom-header d-flex flex-col px-20 pt-0">
      <div className="d-flex justify-between items-center mb-12 pl-8">
        <h4>{title}</h4>
        <span className="golf-icon close big is-bigger cursor-pointer" onClick={props.onClose}></span>
      </div>
      <div className="d-flex justify-between">
        <Icon name="arrow" className="golf-icon left orange-icon cursor-pointer" onClick={decreaseMonth} />
        <h5>{format(date, 'LLLL yyyy')}</h5>
        <Icon name="arrow" className="golf-icon right orange-icon cursor-pointer" onClick={increaseMonth} />
      </div>
    </div>
  )
}
