import React, { lazy, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Toaster } from 'react-hot-toast'
import 'react-placeholder/lib/reactPlaceholder.css'
import { Route, Switch } from 'react-router-dom'
import PuffLoader from 'react-spinners/PuffLoader'
import 'url-search-params-polyfill'
import Footer from './Components/Footer'
import Layout from './Components/Layout'
import HomeRoute from './Components/Route/HomeRoute'
import PrivateRoute from './Components/Route/PrivateRoute'
import UnknownError from './Screens/Error/UnknownError'
import Rounds from './Screens/Rounds'

const NotSignedInModal = lazy(() => import('./Components/Modals/NotSignedInModal'))
const EventPreview = lazy(() => import('./Screens/Preview/Event'))
const EventVerticalCardPreview = lazy(() => import('./Screens/Preview/EventVerticalCard'))
const ClubPreview = lazy(() => import('./Screens/Preview/Club'))
const HubPreview = lazy(() => import('./Screens/Preview/Hub'))
const TeamPreview = lazy(() => import('./Screens/Preview/Team'))
const PlayerPreview = lazy(() => import('./Screens/Preview/Player'))
const HomeAlphaNew = lazy(() => import('./Screens/Homepage/HomeAlphaNew'))
const Events = lazy(() => import('./Screens/Events'))
const AdminEvents = lazy(() => import('./Screens/Events/Admin'))
const OauthSignIn = lazy(() => import('./Screens/Users/OauthSignIn'))
const SignIn = lazy(() => import('./Screens/Users/SignInPage'))
const SignUp = lazy(() => import('./Screens/Users/SignUpPage'))
const Profile = lazy(() => import('./Screens/Users/Profile'))
const ForgotPassword = lazy(() => import('./Screens/Users/ForgotPassword'))
const ChangePassword = lazy(() => import('./Screens/Users/ChangePassword'))
const IframeTest = lazy(() => import('./Screens/Tests/IframeTest'))
const Clubs = lazy(() => import('./Screens/Clubs'))
const Hubs = lazy(() => import('./Screens/Hubs'))
const NotFoundPage = lazy(() => import('./Screens/Error/NotFound'))

function App() {
  return (
    <div className="App">
      <Layout>
        <Suspense
          fallback={
            <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
              <PuffLoader color={'#F26C42'} size={60} />
            </div>
          }>
          <ErrorBoundary FallbackComponent={UnknownError}>
            <NotSignedInModal />
            <Switch>
              <Route exact path={'/preview-event'} component={EventPreview} />
              <Route exact path={'/preview-event-thumbnail'} component={EventVerticalCardPreview} />
              <Route exact path={'/preview-golfer'} component={PlayerPreview} />
              <Route exact path={'/preview-team'} component={TeamPreview} />
              <Route exact path={'/preview-club'} component={ClubPreview} />
              <Route exact path={'/preview-hub'} component={HubPreview} />
              <Route exact path={'/test'} component={IframeTest} />
              <Route
                component={() => (
                  <Layout>
                    <Switch>
                      <HomeRoute exact path={['/', '/home-page']} component={HomeAlphaNew} />
                      <Route path={'/admin/events'} component={AdminEvents} />
                      <Route path={'/events'} component={Events} />
                      <PrivateRoute path={'/profile'} component={Profile} />
                      <PrivateRoute path={'/rounds'} component={Rounds} />
                      <Route path={'/clubs'} component={Clubs} />
                      <Route path={'/hubs'} component={Hubs} />
                      {process.env.NODE_ENV !== 'production' && (
                        <Route path={'/forgot-password'} component={ForgotPassword} />
                      )}
                      {process.env.NODE_ENV !== 'production' && (
                        <Route path={'/change_password'} component={ChangePassword} />
                      )}
                      <Route path={'/login/oauth'} component={OauthSignIn} />
                      {(window.location.hostname.includes('localhost') ||
                        window.location.hostname.includes('dev-frontend-directory')) && (
                        <Route path={'/sign-in'} component={SignIn} />
                      )}
                      <Route path={'/sign-up'} component={SignUp} />
                      <Route component={() => <NotFoundPage />} />
                    </Switch>
                    <Footer />
                  </Layout>
                )}
              />
            </Switch>
            <Toaster position="top-center" />
          </ErrorBoundary>
        </Suspense>
      </Layout>
    </div>
  )
}

export default App
