const initialState = {
  registerModal: {
    isOpen: false,
    strategy: "register",
    showFinishSetupForm: false,
    eventId: "",
  },
  oneClickModal: {
    isOpen: false,
    eventId: "",
  },
};

export interface ModalState {
  eventId?: string;
  registrationTypeId?: string;
  title?: string;
  isOpen: boolean;
  showFinishSetupForm: boolean;
  strategy?: "favorite" | "register" | "oneClick";
}

export const updateModalState = (modalState: Partial<ModalState>) => ({
  type: "UPDATE",
  payload: { ...modalState },
});

export const toggleShowModal = () => ({
  type: "TOGGLE",
  payload: {},
});

export const toggleOneClickModal = () => ({
  type: "TOGGLE_ONE_CLICK_MODAL",
  payload: {},
});

export const resetModalState = () => ({
  type: "RESET",
  payload: {},
});

const modalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE":
      return {
        ...state,
        registerModal: {
          ...action.payload,
        },
      };
    case "TOGGLE_ONE_CLICK_MODAL":
      return {
        ...state,
        oneClickModal: {
          ...state.oneClickModal,
          isOpen: !state.oneClickModal.isOpen,
        },
      };
    case "TOGGLE":
      return {
        ...state,
        registerModal: {
          ...state.registerModal,
          isOpen: !state.registerModal.isOpen,
          showFinishSetupForm: false,
        },
      };
    case "RESET":
      return {
        ...state,
        registerModal: initialState,
      };
    default:
      return state;
  }
};

export default modalsReducer;
