if (!Object.values) {
  Object.values = function (obj) {
    var allowedTypes = ['[object String]', '[object Object]', '[object Array]', '[object Function]']
    var objType = Object.prototype.toString.call(obj)

    if (obj === null || typeof obj === 'undefined') {
      throw new TypeError('Cannot convert undefined or null to object')
    } else if (!~allowedTypes.indexOf(objType)) {
      return []
    } else {
      // if ES6 is supported
      if (Object.keys) {
        return Object.keys(obj).map(function (key) {
          return obj[key]
        })
      }

      var result = []
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          result.push(obj[prop])
        }
      }

      return result
    }
  }
}

if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i) // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
}

if (!Object.hasOwn) {
  Object.hasOwn = Object.call.bind(Object.hasOwnProperty)
}

if (!Promise.prototype.finally) {
  Promise.prototype.finally = function (callback) {
    if (typeof callback !== 'function') {
      return this.then(callback, callback)
    }
    const P = this.constructor || Promise
    return this.then(
      (value) => P.resolve(callback()).then(() => value),
      (err) =>
        P.resolve(callback()).then(() => {
          throw err
        }),
    )
  }
}

if (!String.prototype.padStart) {
  String.prototype.padStart = function (targetLength, padString) {
    targetLength = Math.floor(targetLength) || 0
    if (targetLength < this.length) return String(this)

    padString = padString ? String(padString) : ' '

    var pad = ''
    var len = targetLength - this.length
    var i = 0
    while (pad.length < len) {
      if (!padString[i]) {
        i = 0
      }
      pad += padString[i]
      i++
    }

    return pad + String(this).slice(0)
  }
}
