import React, { useEffect } from 'react'
import { EventFilters } from 'src/Types/Event'
import Button from '../Buttons'
import NotificationDropdown from '../Dropdowns/NotificationDropdown'
import SearchInput from '../Search'

interface NotificationModal {
  close: () => void
  notificationsAllRead: boolean
}

const NotificationModal = (props: NotificationModal) => {
  return (
    <div className="golf-modal__notification is-active">
      <NotificationDropdown notificationsAllRead={props.notificationsAllRead} closeModal={props.close} />
    </div>
  )
}

export default NotificationModal
