import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../Buttons'
import Icon from '../Icons'

interface NotificationItemProps {
  text: string
  className?: string
  hasLink?: boolean
  linkLabel?: string
  isFinished?: boolean
  onClickNotification: () => void
}

const NotificationItem = (props: NotificationItemProps) => {
  const { onClickNotification, linkLabel, hasLink, text, className } = props
  return (
    <div
      className={`${
        className ? className : ''
      } golf-dropdown__notification-item is-1-of-1 cursor-pointer py-16 px-20 relative`}>
      <p
        className="pr-30"
        dangerouslySetInnerHTML={{ __html: text ? text.replace('className', 'class') : '' }}
        onClick={onClickNotification}></p>
      {hasLink && (
        <Link to="" className="golf__link font-extrabold mt-12">
          {linkLabel ?? ''} <Icon name="arrow" className="golf-icon up orange-icon ml-10" />
        </Link>
      )}
    </div>
  )
}

export default NotificationItem
