import React from 'react'
import { connect } from 'react-redux'
import { User } from 'src/Types/User'
import { Route, RouteProps } from 'react-router-dom'

interface HomeRouteProps extends RouteProps {
  user: User
}

const HomeRoute = ({ component: Component, ...rest }: HomeRouteProps) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
  }
}

export default connect(mapStateToProps)(HomeRoute)
