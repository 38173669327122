import '../../Events/styles.scss'

import { useQuery } from '@tanstack/react-query'
import isEqual from 'date-fns/isEqual'
import IframeResizer from 'iframe-resizer-react'
import get from 'lodash/get'
import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import withSizes, { Sizes } from 'react-sizes'
import Button from 'src/Components/Buttons'
import Header from 'src/Components/Header'
import Meta from 'src/Components/Meta'
import PastScoreCard from 'src/Components/Scorecard/PastScoreCard'
import { EventType, ScorecardInfoType } from 'src/Types/Event'
import { Error } from 'src/Types/Request'
import { formatWithTZ } from 'src/utils/dates'

import EventsService from '../../../api/Events'
import { scorecardInfoPlaceholder } from './ScorecardInfoPlaceholder'

interface ParamTypes {
  id?: string
}

interface RoundProps {
  width: number
  userId: string
  seenEvents: EventType[]
}

const mapSizesToProps = ({ width }: Sizes): Partial<RoundProps> => ({
  width,
})

const Round = (props: RoundProps) => {
  const { id } = useParams<ParamTypes>()
  const iframeRef = React.useRef(null)

  const history = useHistory()

  const { isLoading, data: event } = useQuery<EventType, Error>(['rounds', id], EventsService.getEventWithRound)
  const hasScoreCardEvent = event?.user_golfer_id

  const { data: scorecardInfo, error } = useQuery<ScorecardInfoType, Error>(
    [`roundInfo/${id}`],
    () => EventsService.getScorecard(event.id, event.rounds[0].id, event.user_golfer_id),
    {
      enabled: !!hasScoreCardEvent,
    },
  )

  const round = get(event, 'rounds[0]', null)

  const eventStartDate = get(event, 'event_start_date', null)
  const eventEndDate = get(event, 'event_end_date', null)
  const date = eventStartDate ?? eventEndDate
  const timezoneOffset = date ? date.substring(date.length - 6, date.length) : ''

  const formattedEventDate = eventStartDate
    ? formatWithTZ(new Date(eventStartDate), timezoneOffset, 'EEE, MMM do') +
      (eventEndDate && !isEqual(new Date(eventStartDate), new Date(eventEndDate))
        ? ' - ' + formatWithTZ(new Date(eventEndDate), timezoneOffset, 'EEE, MMM do')
        : '')
    : ''

  return (
    <Fragment>
      <Meta title={event?.name} description={event?.description} image={event?.course_album?.thumbnail} />
      <Header hasSlider={false} className={'golf-single-event__header'} />
      <div className={'golf-container relative golf-single-event__top-border mb-40'}>
        <Button
          type={'align-left has-icons reverse-icon mt-32 mb-40 normal-text font-bold reset'}
          label={'Back to league page'}
          iconName={'arrow'}
          iconClassName={'left orange-icon ml-0'}
          onClick={() => history.push(`/events/${event?.id}`)}
          hasIcon
        />
        <h2 className="mt-0 mb-4">{event?.name}</h2>
        {eventStartDate && <p className="font-extrabold text-blue-600 mb-0">{formattedEventDate}</p>}
        {hasScoreCardEvent && scorecardInfo && (
          <Fragment>
            {round && (
              <h4 className="mt-32 mb-24">
                {round.name}{' '}
                <span className="font-normal">
                  ({formatWithTZ(new Date(round.played_at), timezoneOffset, 'EEE, MMM d')})
                </span>
              </h4>
            )}
            <p className="font-extrabold mt-0 mb-20 leading-20">{scorecardInfo?.tee?.name ?? 'Yellow'} - Front 9</p>
            <PastScoreCard className="mb-24" scorecardInfo={scorecardInfo ?? scorecardInfoPlaceholder} isFront={true} />
            <p className="font-extrabold mt-0 mb-20 leading-20">{scorecardInfo?.tee?.name ?? 'Yellow'} - Back 9</p>
            <PastScoreCard scorecardInfo={scorecardInfo ?? scorecardInfoPlaceholder} isFront={false} />
            <hr className={`is-1-of-1 border-t-2 border-ultra-light-grey mt-40 mb-44`} />
          </Fragment>
        )}
        {round && (
          <IframeResizer
            heightCalculationMethod="lowestElement"
            width="100%"
            sizeHeight
            style={{ border: 'none' }}
            scrolling={false}
            forwardRef={iframeRef}
            src={round.leaderboard_url}
          />
        )}
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    userId: state.userReducer.user?.id,
    seenEvents: state.eventHistory.seenEvents,
  }
}

export default connect(mapStateToProps, null)(withSizes(mapSizesToProps)(Round))
