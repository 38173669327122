import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

const defaultOptions = {
  retry: 2,
  staleTime: 1000 * 60,
  cacheTime: 1000 * 60 * 60 * 24,
}

export default function useGolfQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> {
  return useQuery(queryKey, queryFn, { ...defaultOptions, ...options })
}
