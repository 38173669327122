import './styles.scss'

import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

const Footer = () => {
  const history = useHistory()
  const isFooterHidden =
    ['preview-event', 'test'].some((route) => history.location.pathname.includes(route)) || window
      ? 'parentIFrame' in window
      : false

  const GOLFGENIUS_URL = process.env.REACT_APP_BASE_URL == 'prod' ? 'https://www.golfgenius.com' : 'https://ggstest.com'

  return (
    <Fragment>
      <footer className="golf-footer" style={{ display: isFooterHidden ? 'none' : 'block' }}>
        <div className="golf-container d-flex flex-wrap pb-40">
          <div className="is-1-of-1 tablet:is-1-of-5 d-none tablet:d-flex mb-32 tablet:mb-0 pr-40">
            <div className="golf-footer__logo--orange"></div>
          </div>
          <div className="is-1-of-2 tablet:is-1-of-5">
            <ul className="golf-footer__links-wrapper">
              <li className="golf-footer__list-title">
                <p className="text-14 sm_desktop:text-19">PRODUCTS</p>
              </li>
              <li>
                <a href={`${GOLFGENIUS_URL}/tm`} className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Tournament Management</span>
                </a>
              </li>
              <li>
                <a href={`${GOLFGENIUS_URL}/golfshop`} className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Golf Shop</span>
                </a>
              </li>
              <li>
                <a href={`${GOLFGENIUS_URL}/coach360`} className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Coach 360</span>
                </a>
              </li>
              <li>
                <a
                  href={
                    'https://operation36.golf/coaches/?utm_source=golfgenius&utm_medium=referral_link&utm_campaign=golfgeniushomepage'
                  }
                  className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Operation 36 Golf</span>
                </a>
              </li>
              <li>
                <a href={'https://coachnow.io'} className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Coach Now</span>
                </a>
              </li>
              <li>
                <a href={`${GOLFGENIUS_URL}/trip-manager`} className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Trip Manager</span>
                </a>
              </li>
              <li>
                <a
                  href={'https://www.twilightgolfassociation.com'}
                  className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Twilight Golf</span>
                </a>
              </li>
              <li>
                <a href={'https://golfshot.com'} className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Golfshot</span>
                </a>
              </li>
              <li>
                <a href={'https://swingu.com'} className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Swing U</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="is-1-of-2 tablet:is-1-of-5">
            <ul className="golf-footer__links-wrapper">
              <li className="golf-footer__list-title">
                <p className="text-14 sm_desktop:text-19">COMPANY</p>
              </li>
              <li>
                <a href={`${GOLFGENIUS_URL}/our-mission`} className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>About Us</span>
                </a>
              </li>
              <li>
                <a href={`${GOLFGENIUS_URL}/about-us`} className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Executive Team</span>
                </a>
              </li>
              <li>
                <a href={`${GOLFGENIUS_URL}/news`} className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>News</span>
                </a>
              </li>
              <li>
                <a href={`${GOLFGENIUS_URL}/partners`} className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Partners</span>
                </a>
              </li>
              <li>
                <a
                  href={'https://form.jotform.com/222846094088161'}
                  target="_blank"
                  rel="noreferrer"
                  className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Contact Us</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="is-1-of-2 tablet:is-1-of-5">
            <ul className="golf-footer__links-wrapper">
              <li className="golf-footer__list-title">
                <p className="text-14 sm_desktop:text-19">SOCIAL</p>
              </li>
              <li>
                <a
                  href={'https://www.facebook.com/GolfGeniusSoftware/'}
                  target="_blank"
                  rel="noreferrer"
                  className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Facebook</span>
                </a>
              </li>
              <li>
                <a
                  href={'https://twitter.com/GolfGenius'}
                  target="_blank"
                  rel="noreferrer"
                  className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Twitter</span>
                </a>
              </li>
              <li>
                <a
                  href={'https://www.linkedin.com/company/5014111/'}
                  target="_blank"
                  rel="noreferrer"
                  className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>LinkedIn</span>
                </a>
              </li>
              <li>
                <a
                  href={'https://www.instagram.com/golfgeniussoftware/?hl=en'}
                  target="_blank"
                  rel="noreferrer"
                  className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Instagram</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="is-1-of-2 tablet:is-1-of-5">
            <ul className="golf-footer__links-wrapper">
              <li className="golf-footer__list-title">
                <p className="text-14 sm_desktop:text-19">LEGAL</p>
              </li>
              <li>
                <a href={`${GOLFGENIUS_URL}/terms_conditions`} className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Terms of Service</span>
                </a>
              </li>
              <li>
                <a href={`${GOLFGENIUS_URL}/privacy`} className="golf-footer__link text-14 sm_desktop:text-19">
                  <span>Privacy Policy</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <small className="d-none sm_tablet:d-block text-right text-dark-grey">
          &copy; Copyright {new Date().getFullYear()}, Golf Genius Software. All Rights Reserved.
        </small>
      </footer>
    </Fragment>
  )
}

export default Footer
