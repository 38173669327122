import { Honeybadger } from '@honeybadger-io/react'
import { Fragment, useEffect } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { useHistory } from 'react-router-dom'
import Button from 'src/Components/Buttons'
import Header from 'src/Components/Header'

const UnknownError = ({ error, resetErrorBoundary }: FallbackProps) => {
  const history = useHistory()

  const backgroundImage = require('./../../assets/images/error.svg').default

  const handleBackToEvents = () => {
    history.push('/profile/events')
    resetErrorBoundary()
  }

  useEffect(() => {
    Honeybadger.notify(error, {
      name: 'Unknown Error',
    })
  }, [error])

  return (
    <Fragment>
      <Header hasSlider={false} className="golf-single-event__header" />
      <div className="error-page-container d-flex flex-col items-center">
        <figure className="mx-auto mb-44" style={{ backgroundImage: `url(${backgroundImage})` }}></figure>
        <p className="mx-auto mb-20 txt-xl">
          Oops, sorry for that 😢. An error has occurred, check console for more information.
        </p>
        <div className="d-flex justify-center mb-90">
          <Button
            type="reverse-icon primary"
            label="Back To Events"
            iconName="arrow"
            iconClassName="left white-icon"
            onClick={handleBackToEvents}
            hasIcon
          />
        </div>
      </div>
    </Fragment>
  )
}

export default UnknownError
