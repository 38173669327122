import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import format from 'date-fns/format'

// Override date-fns timezone format
export const formatAMPM = (date) => {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes.toString().padStart(2, '0')
  let strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

// const array of months
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const formatDAMPM = (date) => {
  let month = months[date.getMonth()]
  let day = date.getDate()
  day = day < 10 ? '0' + day : day
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes.toString().padStart(2, '0')
  let strTime = month + ' ' + day + ' ' + hours + ':' + minutes + ' ' + ampm
  return strTime
}

export const formatWithTZ = (date, timezoneOffset = '+00:00', formatString = 'EEEE, MMM d, yyyy') => {
  let formattedDate = ''
  try {
    formattedDate = formatInTimeZone(date, timezoneOffset, formatString)
  } catch (e) {
    console.error('TZ FORMAT ERRROR:', e)
    formattedDate = format(date, formatString)
  }
  return formattedDate
}
