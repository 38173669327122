import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { CitySearchResult, ZipCodeSearchResult, SearchResult, SearchResults } from 'src/Types/Event'
import ClipLoader from 'react-spinners/ClipLoader'
import Icon from '../Icons'

interface ContentProps {
  results: SearchResults
  searchText: string
  includeCurrentLocation?: boolean
  filter: (...args: any) => void
  onChange?: (...args: any[]) => any
}

const headerClassName = 'tw-px-3 tw-text-xl tw-mb-3 tw-mt-0 tw-font-bold'
const listItemClassName =
  'tw-py-2 tw-px-3 hover:tw-text-orange-700 hover:tw-bg-grey-100 tw-cursor-pointer tw-transition-all tw-duration-200'

const Content = (props: ContentProps) => {
  const cities: CitySearchResult[] = get(props, 'results.cities', [])
  const events: SearchResult[] = get(props, 'results.events', [])
  const courses: SearchResult[] = get(props, 'results.courses', [])
  const customers: SearchResult[] = get(props, 'results.customers', [])
  const zipCodes: ZipCodeSearchResult[] = get(props, 'results.zip_codes', [])
  const onChange = get(props, 'onChange', () => {})
  const noResults = [cities, events, courses, customers, zipCodes].every((section) => isEmpty(section))
  const [loadingLocation, setLoadingLocation] = useState(false)

  const fetchLocationAndUpdateFilters = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          props.filter(
            {
              filter_latitude: position.coords.latitude,
              filter_longitude: position.coords.longitude,
            },
            'Use my current location',
          )
          setLoadingLocation(false)
        },
        (err) => {
          console.log(err)
        },
      )
    }
  }

  return (
    <div
      className="tw-absolute tw-shadow-lg tw-z-20 tw-bg-white tw-border tw-pt-3 tw-rounded tw-left-0 tw-right-0 tw-w-full tw-mt-1 tw-max-h-[448px]"
      style={{
        overflowY: noResults ? 'hidden' : 'scroll',
        textAlign: loadingLocation ? 'center' : 'initial',
        overflowX: 'hidden',
      }}>
      {loadingLocation && <ClipLoader color={'#F26C42'} loading={true} size={24} />}
      {!loadingLocation && (
        <>
          {props?.includeCurrentLocation && (
            <ul>
              <li
                className={listItemClassName}
                onClick={() => {
                  setLoadingLocation(true)
                  fetchLocationAndUpdateFilters()
                  onChange()
                }}>
                <Icon name="locate" className="golf-icon is-small" />
                <span className="align-middle">Use my current location</span>
              </li>
            </ul>
          )}
          {!isEmpty(cities) && (
            <>
              {props?.includeCurrentLocation && <hr className={`is-1-of-1 border-t-2 border-ultra-light-grey my-12`} />}
              <h4 className={headerClassName}>Cities</h4>
              <ul>
                {cities.map((city) => (
                  <li
                    className={listItemClassName}
                    key={city.id}
                    onClick={() => {
                      props.filter(
                        {
                          filter_latitude: city.latitude,
                          filter_longitude: city.longitude,
                        },
                        city.search_query,
                      )
                      onChange()
                    }}>
                    {city.search_query}
                  </li>
                ))}
              </ul>
            </>
          )}
          {!isEmpty(zipCodes) && (
            <>
              {(props?.includeCurrentLocation || cities.length > 0) && (
                <hr className={`is-1-of-1 border-t-2 border-ultra-light-grey my-12`} />
              )}
              <h4 className={headerClassName}>Zip codes</h4>
              <ul>
                {zipCodes.map((zipCode) => (
                  <li
                    className={listItemClassName}
                    key={zipCode.zip_code}
                    onClick={() => {
                      props.filter(
                        {
                          filter_latitude: zipCode.latitude,
                          filter_longitude: zipCode.longitude,
                        },
                        zipCode.zip_code,
                      )
                      onChange()
                    }}>
                    {`${zipCode.zip_code}, ${zipCode.city}, ${zipCode.state_code}`}
                  </li>
                ))}
              </ul>
            </>
          )}
          {!isEmpty(events) && (
            <>
              {(props?.includeCurrentLocation || zipCodes.length > 0 || cities.length > 0) && (
                <hr className={`is-1-of-1 border-t-2 border-ultra-light-grey my-12`} />
              )}
              <h4 className={headerClassName}>Events</h4>
              <ul>
                {events.map((event) => (
                  <li
                    key={event.id}
                    className={listItemClassName}
                    onClick={() => {
                      props.filter({ event_id: event.id }, event.name)
                      onChange()
                    }}>
                    {event.name}
                  </li>
                ))}
              </ul>
            </>
          )}
          {!isEmpty(courses) && (
            <>
              {(props?.includeCurrentLocation || zipCodes.length > 0 || cities.length > 0 || events.length > 0) && (
                <hr className={`is-1-of-1 border-t-2 border-ultra-light-grey my-12`} />
              )}
              <h4 className={headerClassName}>Courses</h4>
              <ul>
                {courses.map((course) => (
                  <li
                    className={listItemClassName}
                    key={course.id}
                    onClick={() => {
                      props.filter({ course_name: course.name }, course.name)
                      onChange()
                    }}>
                    {course.name}
                  </li>
                ))}
              </ul>
            </>
          )}
          {!!!isEmpty(customers) && (
            <>
              {(props?.includeCurrentLocation ||
                zipCodes.length > 0 ||
                cities.length > 0 ||
                events.length > 0 ||
                courses.length > 0) && <hr className={`is-1-of-1 border-t-2 border-ultra-light-grey my-12`} />}
              <h4 className={headerClassName}>Clubs</h4>
              <ul>
                {customers.map((customer) => (
                  <li
                    key={customer.id}
                    className={listItemClassName}
                    onClick={() => {
                      props.filter({ customer_id: customer.id }, customer.name)
                      onChange()
                    }}>
                    {customer.name}
                  </li>
                ))}
              </ul>
            </>
          )}
          {noResults && props.searchText && <p className="tw-px-4 tw-text-sm">No results were found...</p>}
        </>
      )}
    </div>
  )
}

export default Content
