import "./styles.scss";

import format from "date-fns/format";
import get from "lodash/get";
import React, { Fragment } from "react";
import { RectShape } from "react-placeholder/lib/placeholders";
import { useHistory } from "react-router-dom";

import { EventType } from "../../Types/Event";
import Button from "../Buttons";

const defaultSliderImage = require("../../assets/images/slider-placeholder.jpg").default;

interface StorySliderItemProps {
  hasCta?: boolean;
  backgroundImage?: string;
  event?: EventType;
}

function StorySliderItem(props: StorySliderItemProps) {
  const { push } = useHistory();

  let subtitleArr = [];
  const dateString = get(props, "event.event_start_date", undefined);
  const date = dateString ? format(new Date(dateString), "dd MMM") : null;
  const competition = get(props, "event.rounds[0].competitions[0].name", undefined);
  const courseName = get(props, "event.main_course_name", undefined);
  const cityName = get(props, "event.main_address.city", undefined);
  const stateName = get(props, "event.main_address.state", undefined);

  if (date) subtitleArr.push(`${date}.`);
  if (competition) subtitleArr.push(<em> · </em>, competition);
  if (courseName) subtitleArr.push(<em> · </em>, courseName);
  if (cityName) subtitleArr.push(<em> · </em>, cityName);
  if (stateName) subtitleArr.push(<em> · </em>, stateName);

  const [loaded, setLoaded] = React.useState(false);

  const onLoad = () => {
    setLoaded(true);
  };

  return (
    <Fragment>
      <div
        className={"golf-slider keen-slider__slide"}
        data-test="story-slider-item"
        style={{
          // backgroundImage: `url(${props.event?.course_album?.thumbnail ?? props.backgroundImage})`,
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props.event?.course_album?.thumbnail ?? props.backgroundImage})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {props.event && (
          <div className="golf-slider__text">
            {props.event?.name && <h3 className={"text-white text-center"}>{props.event?.name}</h3>}
            {props.event && <h4 className={"mt-0 text-white text-center font-semibold"}>{subtitleArr}</h4>}
            {props.hasCta && <Button onClick={() => push(`/events/${props.event?.id}`)} type={"primary"} label={"View Event"} />}
          </div>
        )}
        {!loaded && (
          <>
            <img style={{ display: "none" }} src={props.event?.course_album?.thumbnail ?? props.backgroundImage} onLoad={onLoad} />
            <RectShape color="#E0E0E0" style={{ width: "100%", height: "100%", marginRight: 0 }} />
          </>
        )}
      </div>
    </Fragment>
  );
}

StorySliderItem.defaultProps = {
  backgroundImage: defaultSliderImage,
} as Partial<StorySliderItemProps>;

export default StorySliderItem;
