import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import UsersService from 'src/api/Users'
import { setGoBackToPage } from 'src/store/reducers/location'

export const useAuthentication = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const queryParams = new URLSearchParams(useLocation().search)
  const gh = queryParams.get('gh')
  const paramFromGlobalHub = gh === 'true' ? '?gh=true' : ''

  const handleOauthSignIn = () => {
    const goBackToPage = history.location.pathname + paramFromGlobalHub
    dispatch(setGoBackToPage(goBackToPage))
    UsersService.getLoginUrl().then((res) => {
      let https_url
      https_url = res.url.replace(/^http:\/\//i, 'https://')
      window.location.href = https_url
    })
  }

  return { handleOauthSignIn }
}
