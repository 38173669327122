import { configResponsive, useResponsive } from 'ahooks'
import { format } from 'date-fns'
import addMonths from 'date-fns/addMonths'
import parse from 'date-fns/parse'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import DateRangePicker from 'src/Components/Datepickers/DateRangePicker'
import { SimpleSelect } from 'src/Components/Inputs/Select'
import DatePickerModal from 'src/Components/Modals/DatePickerModal'
import SearchModal from 'src/Components/Modals/SearchModal'
import { EventFilters } from 'src/Types/Event'
import { LocationsType } from 'src/Types/Locations'
import Button from '../Buttons'
import DateRangePickerInput from '../Datepickers/DateRangePickerInput'
import SearchInput from '../Search'

interface SearchProps {
  currentLocation?: LocationsType
  handleSearch: (filters: Partial<EventFilters>) => void
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#232323' : '#818181',
    backgroundColor: state.isSelected ? '#fff' : '#fff',
    height: '32px',
    marginBottom: '8px',
    fontWeight: '800',
    fontSize: '16px',
    lineHeight: '20px',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '20px',
    paddingRight: '20px',
    outline: 'none',
    cursor: 'pointer',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '@media only screen and (max-width: 767px)': {
      display: 'none',
    },
  }),
  input: () => ({
    height: 20,
  }),
  valueContainer: (provided, mQuery) => ({
    ...provided,
    border: 'none',
    padding: 0,
    '@media only screen and (max-width: 1023px)': {
      ...provided['@media only screen and (max-width: 1023px)'],
      justifyContent: 'flex-end',
    },
    '@media only screen and (max-width: 767px)': {
      display: 'none',
    },
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: () => ({
    height: '20px',
    padding: 0,
  }),
  dropdownIndicator: () => ({
    height: '20px',
    padding: 0,
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
    padding: 0,
    left: 0,
    borderRadius: '16px',
    marginTop: '28px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '20px 0 0',
    borderRadius: '16px',
    backgroundColor: '#fff',
  }),
  singleValue: (provided, state) => {
    // const opacity = state.isDisabled ? 0.5 : 1;
    // const transition = 'opacity 300ms';
    const color = '#A1A1A1'
    const backgroundColor = state.isFocused ? '#DDE1EA' : '#fff'

    return {
      ...provided,
      color,
      backgroundColor,
      '@media only screen and (max-width: 1023px)': {
        ...provided['@media only screen and (max-width: 1023px)'],
        marginRight: '12px',
      },
    }
  },
}

configResponsive({
  isTableOrMobile: 1224,
})

function Search(props: SearchProps) {
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [showDatePickerModal, setShowDatePickerModal] = useState(false)

  const [filters, setFilters] = useState<Partial<EventFilters>>({
    date_from: format(new Date(), 'yyyy/MM/dd'),
    date_to: format(addMonths(new Date(), 3), 'yyyy/MM/dd'),
  })

  const { isLargeDesktop } = useResponsive()

  const eventOptions = [
    { value: 'all_events', label: 'All' },
    { value: 'open_event', label: 'Open Events' },
    { value: 'league', label: 'Leagues' },
    { value: 'clinic', label: 'Clinics' },
  ]

  const handleDatesChange = ([startDate, endDate]) =>
    setFilters({
      ...filters,
      date_from: format(startDate, 'yyyy/MM/dd'),
      date_to: endDate ? format(endDate, 'yyyy/MM/dd') : null,
    })

  const handleSearchFocus = () => {
    if (window.innerWidth < 768) setShowSearchModal(true)
  }

  useEffect(() => {
    document.body.style.overflow = showSearchModal || showDatePickerModal ? 'hidden' : 'unset'
  }, [showSearchModal, showDatePickerModal])

  const filterDateFrom = filters?.date_from ?? format(new Date(), 'yyyy/MM/dd')
  const filterDateTo = filters?.date_to ?? null

  return (
    <div className={'golf-header__search'}>
      <div className={'is-1-of-1 tablet:flex-1 tablet:is-3-of-12 search__element mt-0'} onClick={handleSearchFocus}>
        <SearchInput
          filter={(value) => setFilters({ ...filters, ...value })}
          defaultValue={filters?.search_query}
          hideRadius
        />
      </div>
      <div className={'homepage-event-type tablet:is-3-of-12 search__element ml-auto'}>
        <label htmlFor="">Event type</label>
        <Select
          {...props}
          styles={customStyles}
          className={'search__element-event'}
          defaultValue={eventOptions[0]}
          options={eventOptions}
          onChange={(option: any) => {
            if (option.value != 'all_events') setFilters({ ...filters, event_type: [option.value] })
          }}
          menuPlacement={'auto'}
        />
        <SimpleSelect
          id={'golf-simple-select'}
          className={'search__element-event'}
          defaultValue={eventOptions[0]}
          options={eventOptions}
          onChange={(option: any) => {
            if (option.value != 'all_events') setFilters({ ...filters, event_type: [option.value] })
          }}
        />
      </div>
      {isLargeDesktop && (
        <DateRangePicker
          label="When"
          onChange={handleDatesChange}
          defaultDates={[new Date(), addMonths(new Date(), 3)]}
          isMobile={!isLargeDesktop}
        />
      )}
      {!isLargeDesktop && (
        <div className="date-range-picker-container search__element tablet:is-1-of-2 is-1-of-1">
          <span className="label mr-20">When</span>
          <DateRangePickerInput
            onClickInput={() => setShowDatePickerModal(true)}
            startDate={format(parse(filterDateFrom, 'yyyy/MM/dd', new Date()), 'EEE, dd MMM')}
            endDate={format(parse(filterDateTo, 'yyyy/MM/dd', new Date()), 'EEE, dd MMM')}
          />
        </div>
      )}
      <div className={'justify-end search__element submit-wrapper mb-0'}>
        <Button
          type={'primary-responsive'}
          label={'Search'}
          iconName={'search'}
          iconClassName={'search ml-12 tablet:ml-0 sm_desktop:ml-12 white-icon'}
          onClick={() => props?.handleSearch(filters)}
          hasIcon
        />
      </div>
      {showSearchModal && (
        <SearchModal
          close={() => setShowSearchModal(false)}
          filter={(value) => setFilters({ ...filters, ...value })}
          defaultValue={filters?.search_query}
        />
      )}
      {showDatePickerModal && (
        <DatePickerModal
          isOpen={showDatePickerModal}
          close={() => setShowDatePickerModal(false)}
          filter={(value) => setFilters({ ...filters, ...value })}
          dateRange={[
            parse(filterDateFrom, 'yyyy/MM/dd', new Date()),
            filterDateTo ? parse(filterDateTo, 'yyyy/MM/dd', new Date()) : null,
          ]}
        />
      )}
    </div>
  )
}

export default Search
