import omit from "lodash/omit";
import { FeesInfo, Registration } from "src/Types/Event";
import { ErrorData } from "src/Types/Request";

interface RegistrationState {
  registration: Registration | {};
  fees: FeesInfo | {};
  error: Partial<ErrorData> | null;
}

const initialState: RegistrationState = {
  registration: {},
  fees: {},
  error: null,
};

export const flattenRegistrationObject = (registration: Partial<Registration>): any => {
  return {
    ...registration,
    golfers: registration?.golfers.map((golfer) => omit(golfer, ["team"])),
    teams: registration?.teams.map((team) => ({
      ...team,
      golfers: team?.golfers.map((golfer) => omit(golfer, ["team"])),
    })),
  };
};

export const createRegistration = (registration: Partial<Registration>) => ({
  type: "CREATE",
  payload: flattenRegistrationObject(registration),
});

export const updateRegistration = (registration: Partial<Registration>) => ({
  type: "UPDATE",
  payload: flattenRegistrationObject(registration),
});

export const updateRegistrationFees = (fees: FeesInfo) => ({
  type: "UPDATE_FEES",
  payload: fees,
});

export const updateLoadingRegistration = (loading: boolean) => ({
  type: "LOADING",
  payload: loading,
});

export const updateRegistrationPayment = (finishedPayment: boolean) => ({
  type: "FINISH_PAYMENT",
  payload: finishedPayment,
});

export const updateError = (error: Partial<ErrorData>) => ({
  type: "ERROR",
  payload: error,
});

export const clearRegistration = () => ({
  type: "CLEAR",
});

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE":
      return {
        ...state,
        registration: {
          ...action.payload,
        },
        fees: {
          ...action.payload.fees,
        },
      };
    case "UPDATE":
      return {
        registration: {
          ...state.registration,
          ...action.payload,
          finishedPayment: false,
        },
        fees: {
          ...action.payload.fees,
        },
      };
    case "UPDATE_FEES":
      return {
        ...state,
        fees: {
          ...action.payload,
        },
      };
    case "LOADING":
      return {
        ...state,
        registration: {
          ...state.registration,
          loading: action.payload,
        },
      };
    case "FINISH_PAYMENT":
      return {
        ...state,
        registration: {
          ...state.registration,
          finishedPayment: action.payload,
        },
      };
    case "ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "CLEAR":
      return {
        registration: {},
        fees: {},
        error: null,
      };
    default:
      return state;
  }
};

export const getRegistration = (state) => state.registration;

export default registrationReducer;
