import './styles.scss'

import { useKeenSlider } from 'keen-slider/react'
import range from 'lodash/range'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import EventPlaceholder from 'src/Components/Placeholder'

import { EventType } from '../../Types/Event'
import StorySliderItem from './StorySliderItem'

interface StorySliderProps {
  images?: Array<string>
  events?: Array<EventType>
  className?: string
  placeholder?: boolean
}

const StorySlider = (props: StorySliderProps) => {
  const timer = useRef<number>()
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const [pause, setPause] = useState<boolean>(false)
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    loop: true,
    slidesPerView: 1,
    slideChanged(s) {
      setPause(true)
      setPause(false)
      setCurrentSlide(s.details().relativeSlide)
    },
  })

  useEffect(() => {
    timer.current = window.setInterval(() => {
      if (!pause && slider) slider.next()
    }, 15000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  const [sliderDots, setSliderDots] = useState(slider ? [...Array(slider.details().size).keys()] : [])

  useEffect(() => {
    if (slider) {
      slider.refresh()
      setSliderDots([...Array(slider.details().size).keys()])
    }
  }, [props.images, slider])

  return (
    <Fragment>
      <div ref={sliderRef} className={`${props.className ?? ''} golf-keen-slider keen-slider`} data-test="story-slider">
        {props?.images?.map((image, index) => (
          <StorySliderItem key={index} backgroundImage={image} />
        ))}
        {props?.events?.map((event, index) => (
          <StorySliderItem key={index} event={event} hasCta />
        ))}
        {props.placeholder &&
          range(1, 2).map((index) => (
            <EventPlaceholder
              key={index}
              className="golf-slider keen-slider__slide"
              containerStyle={{ backgroundColor: 'rgb(224, 224, 224)' }}
              imageStyle={{ width: '', height: 300, marginRight: 0 }}
              textStyle={{ padding: 12 }}
              textRows={0}
              delay={index * 250}
            />
          ))}
        {slider && (
          <div className="golf-keen-slider--dots">
            {sliderDots.length > 1 &&
              sliderDots.map((idx) => {
                return (
                  <button
                    key={idx}
                    onClick={(event) => {
                      if (currentSlide !== idx) slider.moveToSlideRelative(idx)
                      else event.preventDefault()
                    }}
                    className={'dot' + (currentSlide === idx ? ' active' : '')}>
                    <span></span>
                  </button>
                )
              })}
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default StorySlider
