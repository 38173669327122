import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { SignOut, UpdateProfile } from "src/store/reducers/user";
import { User } from "src/Types/User";

interface PrivateRouteProps extends RouteProps {
  redirectUrl?: string;
  user: User;
  publicOnly?: boolean;
  adminOnly?: boolean;
}

const PrivateRoute = ({ component: Component, user, publicOnly, adminOnly, ...rest }: PrivateRouteProps) => {
  const cookies = new Cookies();
  const token = cookies.get("token");

  const expiredToken = token == null;
  const redirectUrl = publicOnly ? "/" : rest.redirectUrl ?? "/";
  const shouldRedirect = adminOnly ? user?.role !== "admin" : publicOnly ? !expiredToken : expiredToken;

  return <Route {...rest} render={(props) => (!shouldRedirect ? <Component {...props} /> : <Redirect to={redirectUrl} />)} />;
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (user: User) => dispatch(UpdateProfile(user)),
    signOut: () => dispatch(SignOut()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
