import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import UsersService from "src/api/Users";
import Button from "../Buttons";
import "./styles.scss";

interface ProfileDropdownProps {
  signOut: (noRedirect?: boolean) => void;
  className?: string;
  hasProfile: boolean;
}
const ProfileDropdown = (props: ProfileDropdownProps) => {
  const handleLogout = () => {
    UsersService.logout().then((res) => {
      if (res.logout_url) window.location.href = res.logout_url;
      props.signOut(true);
    });
  };

  return (
    <Fragment>
      <div className={`${props.className ? props.className : ""} golf-container golf-dropdown golf-dropdown__profile py-14 sm_tablet:py-0 rounded-16`}>
        <ul className={`golf-dropdown__links-wrapper is-active`}>
          <li className={"golf-dropdown__list-item"}>
            <Link to={props.hasProfile ? "/profile" : "/profile/setup"}>View profile</Link>
          </li>
          <li className={"golf-dropdown__list-item"}>
            <Link to={"/profile/events/upcoming"}>My events</Link>
          </li>
          {/* <p className="d-flex items-center justify-end">
            Continue registrations<span className="dot ml-20"></span>
          </p> */}
          <hr className="is-1-of-1 border-t-2" />
          <li className="golf-dropdown__list-item">
            <Button type={"normal-text reset"} label={"Logout"} onClick={handleLogout} />
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

ProfileDropdown.defaultProps = {} as Partial<ProfileDropdownProps>;

const mapStateToProps = (state) => {
  return {
    hasProfile: state.userReducer.user.profile != null,
  };
};

export default connect(mapStateToProps, null)(ProfileDropdown);
