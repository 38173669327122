import { FeesInfo, Round, RoundRegistration } from "src/Types/Event";
import omit from "lodash/omit";
import get from "lodash/get";

interface RoundRegistrationState {
  rounds: Round[];
  allRounds: Round[];
  registration: RoundRegistration | {};
  fees: FeesInfo | {};
}

const initialState: RoundRegistrationState = {
  rounds: [],
  allRounds: [],
  registration: {},
  fees: {},
};

export const updateRoundRegistration = (registration: Partial<RoundRegistration>) => ({
  type: "UPDATE_ROUND_REGISTRATION",
  payload: omit(registration, ["golfers", "teams"]),
});

export const updateRegistrationRounds = (rounds: Round[]) => ({
  type: "UPDATE_ROUNDS",
  payload: rounds,
});

export const updateAllRegistrationRounds = (rounds: Round[]) => ({
  type: "UPDATE_ALL_ROUNDS",
  payload: rounds,
});

export const resetRegistrationRounds = () => ({
  type: "RESET_ROUNDS",
});

const registrationRoundsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_ROUNDS":
      return {
        ...state,
        rounds: [...action.payload],
      };
    case "UPDATE_ALL_ROUNDS":
      return {
        ...state,
        allRounds: [...action.payload],
        rounds: [...action.payload],
      };
    case "UPDATE_ROUND_REGISTRATION":
      return {
        ...state,
        registration: {
          ...state.registration,
          id: action.payload?.id ?? get(state, "registration.id"),
          ...action.payload,
        },
        fees: {
          ...state.fees,
          ...action.payload.fees,
        },
      };
    case "RESET_ROUNDS":
      console.log(`state.allRounds`, state.allRounds);
      return {
        ...state,
        rounds: [...state.allRounds],
      };
    default:
      return state;
  }
};

export const getRegistrationRounds = (state) => state.rounds;

export default registrationRoundsReducer;
