import { User, UserProfile } from 'src/Types/User'
import request from './request'

export default class UsersService {
  static getLoginUrl() {
    return request({
      url: '/users/login_url',
      method: 'GET',
    })
  }

  static signInOauth(code: string) {
    return request({
      url: '/users/ouath_login?code=' + code,
      method: 'POST',
    })
  }

  static signIn(data): Promise<User> {
    return request({
      url: '/users/login',
      method: 'POST',
      data,
    })
  }

  static signUp(data) {
    return request({
      url: '/users/register',
      method: 'POST',
      data: {
        ...data,
        base_url: process.env.REACT_APP_BASE_URL.trimEnd(),
      },
    })
  }

  static updateProfile(id, data) {
    return request({
      url: `/users/${id}/profile`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data?._boundary}`,
      },
    })
  }

  static getProfile(id): Promise<UserProfile> {
    return request({
      url: `/users/${id}/profile`,
      method: 'GET',
    })
  }

  static checkGHIN(data) {
    return request({ url: '/ghin_handicaps', method: 'POST', data })
  }

  static updateProfileImage(id, data) {
    return request({
      url: `/users/${id}/profile/profile_image`,
      method: 'POST',
      data,
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data?._boundary}`,
      },
    })
  }

  static deleteProfileImage(id) {
    return request({
      url: `/users/${id}/profile/profile_image`,
      method: 'DELETE',
    })
  }

  static makeFavorite(userId: number, eventId: string) {
    return request({
      url: `/users/${userId}/favorite_events/${eventId}`,
      method: 'POST',
    })
  }

  static removeFavorite(userId: number, eventId: string) {
    return request({
      url: `/users/${userId}/favorite_events/${eventId}`,
      method: 'DELETE',
    })
  }

  static updatePassword(userId: number, data) {
    return request({
      url: `/users/${userId}/profile/update_password`,
      method: 'POST',
      data,
    })
  }

  static forgotPassword(data) {
    return request({
      url: `/password`,
      method: 'POST',
      data,
    })
  }

  static resetPassword(data) {
    return request({
      url: `/password`,
      method: 'PATCH',
      data,
    })
  }

  static getTransactions(userId, filters) {
    return request({
      url: `/users/${userId}/transactions`,
      method: 'GET',
      params: filters,
      apiVersion: 'v2',
    })
  }

  static getPhonePrefixes() {
    return request({
      url: `/phone_number_prefixes`,
      method: 'GET',
    })
  }

  static logout() {
    return request({
      url: `/users/logout`,
      method: 'DELETE',
    })
  }
}
