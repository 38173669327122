import { useState } from 'react'
import { SelectOption } from './DropdownInput'

interface SelectProps {
  id: string
  defaultValue?: SelectOption
  className?: string
  getRef?: string
  onChange?: (option: any) => void
  unselected?: SelectOption
  options: SelectOption[]
}

const Select = (props: SelectProps) => {
  const [selected, setSelected] = useState(props.defaultValue.value)

  return (
    <select
      className={`golf-simple-select mobile-dropdown ${props.className ?? ''}`}
      id={props.id}
      ref={props.getRef}
      value={selected}
      onChange={(ev) => {
        props.onChange({ label: ev.target.value, value: ev.target.value })
        setSelected(ev.target.value)
      }}>
      {props.unselected && <option value={props.unselected.value}>{props.unselected.label}</option>}
      {props.options &&
        props.options.map((option, index) => (
          <option key={`react-select-native-option${index}`} value={option.value} selected={selected === option.value}>
            {option.label}
          </option>
        ))}
    </select>
  )
}

export { Select as SimpleSelect }
