import React, { useEffect } from "react";
import { EventFilters } from "src/Types/Event";
import Button from "../Buttons";
import SearchInput from "../Search";

interface SearchModalProps {
  close: () => void;
  filter: (filters: Partial<EventFilters>) => void;
  defaultValue?: string;
}

const SearchModal = (props: SearchModalProps) => {
  return (
    <div className="golf-modal__search is-active">
      <h2 className="mt-0">Search</h2>
      <Button type={"close-modal reset"} hasIcon={true} iconName={"golf-icon close ultra-dark-grey-icon is-large"} onClick={props.close} />
      <SearchInput
        filter={props.filter}
        hideRadius={true}
        className={"golf-modal__search--input"}
        iconName={"search"}
        iconClassName={"golf-icon search ultra-dark-grey-icon"}
        defaultValue={props.defaultValue}
        onChange={props.close}
        autoFocus
      />
    </div>
  );
};

export default SearchModal;
