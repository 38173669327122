import { Honeybadger } from '@honeybadger-io/react'
import axios, { AxiosResponse } from 'axios'
import { deserialize } from 'jsonapi-fractal'
import jwtDecode from 'jwt-decode'
import isEmpty from 'lodash/isEmpty'
import toast from 'react-hot-toast'
import { SignOut } from 'src/store/reducers/user'
import Cookies from 'universal-cookie'
import { addEvent } from '../store/reducers/eventHistory'

const cookies = new Cookies()

const client = (() => {
  return axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
  })
})()

export const checkTokenExpiration = (token: string) => {
  let isExpired = true

  if (typeof token !== 'undefined') {
    const decoded = jwtDecode<{ exp: number }>(token)
    const now = Math.round(Date.now() / 1000)
    isExpired = decoded.exp < now
  }
  return isExpired
}

const request = function (options: any, store?: any): Promise<any> {
  const onSuccess = function (response: any) {
    const excludedPathRegex = /(scorecard|password|search_results|search|round_attending|login_url|logout)/g
    const shouldNotDeserialize =
      response.data.client_secret || excludedPathRegex.test(response.config.url) || isEmpty(response.data)
    let data = shouldNotDeserialize ? response?.data ?? response : deserialize(response.data)

    if (typeof data !== 'string') {
      const pagy = response.data?.pagy
      const smart_filters = response.data?.smart_filters

      const minLat = response.data?.min_lat
      const minLng = response.data?.min_lng
      const maxLat = response.data?.max_lat
      const maxLng = response.data?.max_lng

      data.meta = response?.data?.data?.meta?.data

      if (pagy) return { data, pagy, smart_filters }
      if (minLat && minLng && maxLat && maxLng) return { data, minLat, minLng, maxLat, maxLng }
    }

    return data
  }

  const onError = function (error: any) {
    const isPastEventRegex = /(\/events\/[a-zA-Z0-9-*])/g
    const isPastEvent = isPastEventRegex.test(window.location.pathname)

    Honeybadger.notify(error, {
      name: 'API Error',
    })

    if (!isPastEvent && error?.status === 401) {
      store.dispatch(SignOut())
      cookies.remove('token')
      toast.error('Your session has expired. Please sign in again.')
      setTimeout(() => {
        window.location.reload()
      }, 500)
    } else {
      return Promise.reject(error)
    }
  }

  if (cookies.get('token')) {
    options.headers = {
      ...options.headers,
      Authorization: 'Bearer ' + cookies.get('token'),
    }
  }
  options.url = (options.apiVersion ?? '/v1') + options.url

  return client(options).then(onSuccess).catch(onError)
}

export const responseInterceptor = (store) => {
  client.interceptors.response.use(
    (response) => {
      if (response.headers.authorization) {
        const token = response.headers.authorization.split(' ')[1]
        const decoded: any = jwtDecode(token)

        cookies.set('token', token, {
          path: '/',
          expires: new Date(decoded.exp * 1000),
        })
      }

      if (/\/events\/([a-zA-Z0-9-*]*)/g.test(response.config.url) && !response.config.url.includes('slim_index')) {
        try {
          store.dispatch(addEvent(deserialize(response.data)))
        } catch (e) {}
      }
      return response
    },
    (error) => {
      const isPastEventRegex = /(\/events\/[a-zA-Z0-9-*])/g
      const isPastEvent = isPastEventRegex.test(window.location.pathname)
      const err = error?.response

      if (!isPastEvent && err?.status === 401) {
        store.dispatch(SignOut())
        cookies.remove('token')
        toast.error('Your session has expired. Please sign in again.')
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }

      Honeybadger.notify(error, {
        name: 'API Error',
      })

      return Promise.reject(err)
    },
  )
}

export const requestInterceptor = (store) => {
  client.interceptors.request.use((config) => {
    if (checkTokenExpiration(cookies.get('token'))) {
      store.dispatch(SignOut())
      cookies.remove('token')
    }
    return config
  })
}

export default request
