import { EventFilters } from "src/Types/Event";

const initialState = {
  filters: {},
};

export const Filter = (filters: Partial<EventFilters>) => ({
  type: "FILTER",
  payload: { ...filters },
});

const eventFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FILTER":
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export const getFilters = (state) => state.filters;

export default eventFiltersReducer;
