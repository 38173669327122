import React, { CSSProperties, useEffect, useState } from "react";
import { RectShape, TextBlock } from "react-placeholder/lib/placeholders";
import "./styles.scss";

export interface EventPlaceholderProps {
  imageStyle?: CSSProperties;
  textStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  textRows?: number;
  delay?: number;
  className?: string;
}
const EventPlaceholder = (props: EventPlaceholderProps) => {
  return (
    <div
      className={`d-flex placeholder-container ${props.className ?? ""}`}
      style={{
        ...props.containerStyle,
        animationFillMode: "backwards",
        animationDelay: props.delay != null ? props.delay + "ms" : "0ms",
      }}
    >
      <RectShape
        color="#E0E0E0"
        style={{
          ...props.imageStyle,
          width: props.imageStyle?.width ?? 200,
          height: props.imageStyle?.height ?? window.innerWidth >= 1280 ? 140 : window.innerWidth >= 768 ? 108 : 80,
          borderRadius: 16,
        }}
      />
      <TextBlock color="#E0E0E0" rows={props.textRows ?? 5} style={{ ...props.textStyle }} />
    </div>
  );
};

export default EventPlaceholder;
