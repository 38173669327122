import { deserialize } from "jsonapi-fractal";
import isEmpty from "lodash/isEmpty";
import LocationsService from "src/api/Locations";
import { EventType } from "src/Types/Event";
import { LocationsType, NearEventsType } from "src/Types/Locations";

const initialState = {
  location: {
    near_events: {},
  },
  goBackToPage: null,
};

export const fetchLocationSuccess = (location: LocationsType | { near_events: any }) => ({
  type: "FETCH_LOCATION",
  payload: { ...location },
});

export const makeEventFavorite = (popularEvents: EventType[]) => ({
  type: "FAVORITE",
  payload: [...popularEvents],
});

export const setGoBackToPage = (goBackToPage: string) => ({
  type: "SET_GO_BACK_TO_PAGE",
  payload: goBackToPage,
});

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_GO_BACK_TO_PAGE":
      return {
        ...state,
        goBackToPage: action.payload,
      };
    case "FETCH_LOCATION":
      return {
        ...state,
        location: {
          ...action.payload,
        },
      };
    case "FAVORITE":
      return {
        ...state,
        location: {
          ...state.location,
          near_events: {
            ...state.location.near_events,
            popular: [...action.payload],
          },
        },
      };
    default:
      return state;
  }
};

export const fetchLocation = () => async (dispatch, getState) => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        const response = await LocationsService.getWithCoords({
          latitude,
          longitude,
          include_near_events: true,
        }).catch((err) => {
          console.log(err);
        });
        const nearEvents = {
          featured: deserialize(response.near_events.featured),
          popular: deserialize(response.near_events.popular),
          more: deserialize(response.near_events.more),
        };
        dispatch(fetchLocationSuccess({ ...response, near_events: nearEvents }));
      },
      (err) => {
        console.log(err);
        dispatch(fetchLocationSuccess({ near_events: {} }));
      },
    );
  }
};

export const makeFavorite = (eventId: string, isFavorite: boolean) => async (dispatch, getState) => {
  const popularEvents: any = [...getState().locationReducer.location.near_events.popular].map((event) => {
    return event.id == eventId ? { ...event, is_user_favorite_event: isFavorite } : event;
  });
  dispatch(makeEventFavorite(popularEvents));
};

export default locationReducer;
