import { QueryFunctionContext } from '@tanstack/react-query'
import { EventType, FilteredEventsResponse } from 'src/Types/Event'
import { EventLocationsResponse } from 'src/Types/EventLocation'
import { SlimEventsResponse } from 'src/Types/SlimEvent'
import request from './request'

export default class EventsService {
  static getFiltered(params): Promise<FilteredEventsResponse> {
    return request({
      url: '/events',
      method: 'GET',
      params,
    })
  }

  static getFilteredSlim(params): Promise<SlimEventsResponse> {
    return request({
      url: '/events/slim_index',
      method: 'GET',
      params,
    })
  }

  static getFilteredLocations(params): Promise<EventLocationsResponse> {
    return request({
      url: '/events/map_locations',
      method: 'GET',
      params,
    })
  }

  static homePageBySection(params): any {
    return request({
      url: '/home_pages',
      method: 'GET',
      params,
    })
  }

  static getById(id): Promise<EventType> {
    return request({
      url: `/events/${id}`,
      method: 'GET',
    })
  }

  static getScorecard(eventId, roundId, golferId): any {
    return request({
      url: `/events/${eventId}/rounds/${roundId}/golfers/${golferId}/scorecard`,
      method: 'GET',
    })
  }

  static getSearchResults(
    query: string,
    includeEvents: boolean,
    includeCourses: boolean,
    includeCities: boolean,
    includeCustomers: boolean,
    includeZipCodes: boolean,
  ): any {
    return request({
      url: `/search_results?query=${query}&include_events=${includeEvents}&include_cities=${includeCities}&include_customers=${includeCustomers}&include_zip_codes=${includeZipCodes}`,
      method: 'GET',
    })
  }

  static getEventWithRound({ queryKey }: QueryFunctionContext): any {
    const [_key, roundId] = queryKey ?? []
    return request({
      url: `/rounds/${roundId}`,
      method: 'GET',
    })
  }

  static getCourseById(courseId: string): any {
    return request({
      url: `/courses/${courseId}`,
      method: 'GET',
    })
  }

  static attendRound(userId, roundId, attending): any {
    return request({
      url: `/user/${userId}/rounds/${roundId}/round_attending`,
      method: 'PATCH',
      data: { attending },
    })
  }

  static getHubsEvents(hubId, params): any {
    return request({
      url: `/hubs/${hubId}/events`,
      method: 'GET',
      params,
    })
  }

  static getHubsFilteredLocations(hubId, params): Promise<EventLocationsResponse> {
    return request({
      url: `/hubs/${hubId}/map_locations`,
      method: 'GET',
      params,
    })
  }
}
