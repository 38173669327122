const initialState = {
  strategy: "register",
  eventId: "",
  registrationTypeId: "",
};

export interface RegistrationInfoState {
  eventId?: string;
  registrationTypeId?: string;
  strategy?: "favorite" | "register" | "oneClick";
}

export const updateRegistratioInfoState = (registrationInfoState: Partial<RegistrationInfoState>) => ({
  type: "UPDATE",
  payload: { ...registrationInfoState },
});

export const resetRegistratioInfoState = () => ({
  type: "RESET",
  payload: {},
});

const registrationInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET":
      return {
        initialState,
      };
    default:
      return state;
  }
};

export default registrationInfoReducer;
