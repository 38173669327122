import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "src/Components/Route/PrivateRoute";
import Round from "./Round";

const idRegexp = "([a-zA-Z0-9-*]*)";

function Rounds() {
  let match = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute path={`${match.url}/:id${idRegexp}`} component={Round} />
    </Switch>
  );
}

export default Rounds;
