import { EventType } from 'src/Types/Event'

const initialState = {
  seenEvents: [],
  limit: 9,
}

export const addEvent = (event: Partial<EventType>) => ({
  type: 'ADD',
  payload: event,
})

const eventHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD':
      const newEvents = [...state.seenEvents]
      const eventIndex = newEvents.findIndex((e) => e.id == action.payload.id)

      if (eventIndex == -1) {
        if (newEvents.length == state.limit) newEvents.splice(-1, 1)

        newEvents.unshift(action.payload)
      } else {
        newEvents.splice(eventIndex, 1)
        newEvents.unshift(action.payload)
      }

      return {
        ...state,
        seenEvents: newEvents,
      }
    default:
      return state
  }
}

export const getSeenEvents = (state) => state.seenEvents

export default eventHistoryReducer
